import React from 'react';

const sizes = {
  default: `py-3 px-6`,
  sm: `py-2 px-2`,
  md: `py-3 px-4 `,
  lg: `py-4 px-8 `,
  xl: `py-5 px-16 `
};



const Button = ({ children, className = "", size, type = "button", formaction = "" }) => {

  return (
    <button
      type={ type }

      className={`
        ${sizes[size] || sizes.default}
        ${className}


    `}

    >

      {children}

    </button>
  );
};

export default Button;


import React from 'react';
import { Link } from 'gatsby';

import Layout from '../../components/layout/Layout';
import FeatureCard from '../../components/FeatureCard';

import featuresData from '../../data/productos/secadores-de-manos/features-data';
import { StaticImage } from "gatsby-plugin-image"
import SplitSection from '../../components/SplitSection';
import SplitSectionB2b from '../../components/SplitSectionB2b';
import LeadForm from '../../components/LeadForm';
import NewsletterSub from '../../components/NewsletterSub';

const siteVerOpts = [

  {
    label: 'Ecuador',
    value: '',


  },

  {
    label: 'Internacional',
    value: '/',


  },
];

const langOpts = [


  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: `/export/en/products`,

  },


];

const seo={
  title: "Secadores de Manos",
  desc:  "Secadores de manos para un adecuado higiene de manos.",
  image: "secadores-de-manos.jpg",
  pathname: "/secadores-de-manos",
}

export default function Named  ({location})  {


  return(
  <Layout location={location} crumbLabel="Secadores de Manos"
  siteVerOpts={siteVerOpts} langOpts={langOpts}
  title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>


<section id="Absorbentes" className="mt-14">
              <div className="">
               <h1 className="text-5xl font-semibold text-center mb-2">Secadores de Manos</h1>
                <h2 className="text-4xl font-semibold text-center mb-8 text-primary-lighter"> con diseños elegantes y modernos</h2>
              </div>
            </section>



<section id="productos quimicos descripcion" className="mt-14">
<div className="relative bg-blue-900">
  <div className="h-56 bg-blue-900 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
  <StaticImage src="../../images/banner-secadores-de-manos.jpg"
                      className="flex justify-center "
                      alt="Secadores de manos de alta calidad"
                      placeholder="blurred"
                      layout="constrained"
                       />
  </div>
  <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16 pt-28">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
      <p className=" text-xl text-leftfont-normal text-white pb-6">
      <ul className="list-disc ml-8">
                <li>Ideales para un adecuado higiene de manos, ya que evita tener contacto con otras superficies entre lavado y secado.</li>
                <li>Perfectos para colocar en pared y ahorrar espacio. </li>
                <li> Permiten minimizar residuos.</li>
                <li> Recomendados para uso de alto tráfico.</li>
              </ul> </p>
      
    </div>
  </div>
</div>
</section>


<section id="categorias productos quimicos" className=" mt-26 bg-gray-200 pt-16 pb-10">
  <h3 className="text-primary-default  title-font text-center text-4xl font-semibold  ">Portafolio de Secadores de Manos</h3>
         
    <div className="relative bg-gray-200 px-4 sm:px-6 lg:pt-6 lg:pb-28 lg:px-8">
     <div className="relative max-w-7xl mx-auto mt-10">
      <div className=" max-w-lg mx-auto grid gap-8 lg:grid-cols-4 lg:max-w-none">

        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden ">
          <div className="flex-1 bg-gray-100 p-6 flex flex-col justify-between">
            <div className="flex-1 ">

                <div className="flex flex-wrap mx-auto justify-center ">
                  <h3 className="text-3xl font-semibold text-secondary-lighter text-center leading-relaxed">Secadores de Manos</h3>
                </div>
              
               <div className="flex flex-wrap mx-auto justify-left">
                 <Link to="/productos/secadores-de-manos/expair-automatico" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Secador de manos expair automático
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link><br/>
                 <Link to="/productos/secadores-de-manos/supair-automatico" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Secador de manos supair automático
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link><br/>
              </div>
          </div>
        </div>
      </div>

     </div>
    </div>
  
  </div>
</section>





<SplitSectionB2b/>

<LeadForm/>
<NewsletterSub/>

  </Layout>
)
}

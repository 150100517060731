import React from 'react';
import { Link } from 'gatsby';
import Button from '../components/Button';
import { m, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image"


const containerAnim = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.8, type: "spring", stiffness: 100} },
  hidden: { opacity: 0, scale: 0 }
};

const LeadForm = ({className}) => {

  const control = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);


return(

  <m.section
    id="leadform"
    className={` bg-right md:bg-center bg-no-repeat bg-fill ${ className }`}
    ref={ref}
    initial="hidden"
    animate={control}
    variants={containerAnim}
  >
  <div className="relative bg-lime-500">
  <div className="h-56 bg-secondary-lighter sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
  <StaticImage src="../images/call-center-unilimpio.jpg"
                      className=" object-cover"
                      alt="Solicite una cotización con Unilimpio"
                      placeholder="blurred"
                      layout="constrained"
                       />
  </div>
  <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16 pt-20 ">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
      <h2 className="mt-4 text-xl sm:text-4xl md:text-4xl font-bold text-white text-center ">¡SOLICITE UNA COTIZACIÓN AHORA!</h2>
      <p className="mt-6 text-2xl text-left font-normal text-white">Gracias por su interés en Unilimpio S.A. Estaremos gustosos de atenderle para brindarle mayor información acerca de nuestros productos de higiene profesional.</p>
      <div className="mt-8">
      <div className="flex flex-wrap mx-auto justify-center pt-4">
         <Link to="/contactenos/">
          <Button className=" text-primary-lighter text-semibold text-xl  rounded-xl transition duration-200 ease-in-out bg-white shadow-md hover:bg-white hover:shadow-lg transform hover:-translate-y-1 hover:scale-105 focus:outline-none" size="s" type="submit">Contáctese</Button>
         </Link>
       </div>
      </div>
    </div>
  </div>
</div>
</m.section>
);
}

export default LeadForm;


import React from 'react';
import { m, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { useEffect } from "react";


const containerAnim = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.8, delayChildren: 0.5 } },
  hidden: { opacity: 0, scale: 0 }
};

const imageAnim = {
  visible: {opacity: 1, scale: 1, transition: { duration: 0.3, type: "spring", stiffness: 100 }},
  hidden: { opacity: 0, scale: 0 }

};


const SplitSection = ({ id, className, primarySlot, secondarySlot, reverseOrder }) => {

  const control = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);


  return(
      <m.section
        ref={ref}
        initial="hidden"
        animate={control}
        variants={containerAnim}
        id={id}
        className={` ${className}`}>
          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/2">{primarySlot}</div>
            <div
              className={`mt-10 md:mt-0 w-full md:w-1/2 ${reverseOrder && `order-last lg:order-first`}`}
            >
              <m.div variants={imageAnim}>
                {secondarySlot}
              </m.div>
            </div>
          </div>
      </m.section>
  );
};

export default SplitSection;
